@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
}
body .threads_group {
  padding: 10px;
}
body .threads_group .thread_item {
  cursor: pointer;
  position: relative;
  padding: 10px;
  border: 1px solid rgba(100, 121, 143, 0.122);
  border-bottom: 0;
  display: flex;
  color: #202124;
  z-index: 1;
  font-size: 14px;
  gap: 10px;
}
body .threads_group .thread_item:hover {
  box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  z-index: 2;
}
body .threads_group .thread_item:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
body .threads_group .thread_item:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom: 1px solid rgba(100, 121, 143, 0.122);
}
body .threads_group .thread_item .msg_cc {
  font-weight: 600;
  width: 200px;
  flex-shrink: 0;
}
body .threads_group .thread_item .body {
  width: calc(100% - 210px);
}
body .threads_group .thread_item .body .snippet_group {
  display: flex;
  white-space: nowrap;
  gap: 10px;
  align-items: center;
}
body .threads_group .thread_item .body .snippet_group .subject {
  font-weight: 600;
}
body .threads_group .thread_item .body .snippet_group .lastmessage {
  flex-grow: 0;
  color: #5f6368;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 auto 0 0;
}
body .threads_group .thread_item .body .snippet_group .date_time {
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 600;
}
body .messages_group {
  height: 100vh;
  padding: 10px 10px 0 10px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
}
body .messages_group .subject {
  font-weight: 600;
}
body .messages_group .compose {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  background: white;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid rgb(100, 121, 143);
}
body .messages_group .messages {
  border-bottom: 1px solid rgba(100, 121, 143, 0.122);
  padding: 0;
}
body .messages_group .messages:first-child {
  padding-top: 0;
}
body .messages_group .messages .header {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 10px 0 10px 0;
  gap: 10px;
}
body .messages_group .messages .header .txt {
  flex-shrink: 1;
  overflow: hidden;
}
body .messages_group .messages .header .txt .from {
  font-weight: 600;
  white-space: nowrap;
  display: flex;
  gap: 10px;
  align-items: center;
}
body .messages_group .messages .header .txt .from span {
  font-weight: 400;
  font-size: 12px;
  color: #5f6368;
}
body .messages_group .messages .header .txt .snippet {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex-grow: 0;
  font-size: 12px;
}
body .messages_group .messages .header .date {
  font-size: 12px;
  color: #5f6368;
  margin: 0 0 0 auto;
  flex-shrink: 0;
}
body .messages_group .messages .header svg {
  height: 10px;
  width: 10px;
  flex-shrink: 0;
}
body .messages_group .messages .body {
  padding: 10px 0;
}
body .messages_group .messages .switch {
  --l: 4px;
  background: rgba(100, 121, 143, 0.122);
  display: flex;
  gap: 3px;
  margin: 0 0 10px 0;
  padding: 5px;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}
body .messages_group .messages .switch > div {
  height: var(--l);
  width: var(--l);
  background: #202124;
  border-radius: 50%;
}
body .messages_group .messages .quotebody {
  background: rgba(100, 121, 143, 0.122);
  padding: 10px;
  border-radius: 10px;
}
body .messages_group .messages .attachments_group {
  border: 1px solid rgba(100, 121, 143, 0.122);
  padding: 10px 10px 0 10px;
  margin: 0 0 10px 0;
  border-radius: 10px;
}
body .messages_group .messages .attachments_group .title {
  font-weight: 600;
}
body .messages_group .messages .attachments_group .attachment_flex {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 10px 0;
}
body .messages_group .messages .attachments_group .attachment_flex .attachment_items {
  border: 1px solid rgba(100, 121, 143, 0.122);
  padding: 10px 20px;
  border-radius: 20px;
  text-decoration: unset;
  color: #202124;
  display: flex;
  align-items: center;
  gap: 10px;
}
body .messages_group .messages .attachments_group .attachment_flex .attachment_items svg {
  height: 20px;
  width: auto;
}
body .sendmessage {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  background: rgba(0, 0, 0, 0.2);
}
body .sendmessage > div {
  padding: 40px 20px 20px 20px;
  border-radius: 10px;
  background: #fff;
  position: relative;
  flex-basis: 500px;
  max-height: 100vh;
}
body .sendmessage > div .header {
  display: flex;
}
body .sendmessage > div .header svg {
  position: absolute;
  height: 30px;
  width: auto;
  top: 5px;
  right: 5px;
}
body .sendmessage > div .btn {
  background: #234278;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  border-radius: 10px;
  margin: 10px 0 0 0;
  text-align: center;
}
body .sendmessage > div .btn.border_btn {
  border: 1px solid #234278;
  background: #fff;
  color: #234278;
}
body .sendmessage > div .quill {
  text-align: center;
}
body .sendmessage > div .quill .ql-container {
  overflow: auto;
  max-height: calc(100vh - 200px);
}
body .result {
  display: none;
}
body .btn_group {
  display: flex;
  gap: 10px;
}
body .btn_group .btn {
  width: 100%;
}
body .more_messages {
  padding: 5px 10px;
  border: 1px solid #234278;
  color: #234278;
  font-weight: 600;
  font-size: 16px;
  border-radius: 20px;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modal {
  position: fixed;
  inset: 0px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.2666666667);
}
.modal .closebtn {
  position: fixed;
  z-index: 100;
  height: 32px;
  width: 32px;
  top: 10px;
  right: 10px;
  background: #000;
  padding: 3px;
  border-radius: 0 10px 0 10px;
  cursor: pointer;
}
.modal iframe,
.modal img {
  border-radius: 10px;
  max-height: 100%;
  max-width: 100%;
}